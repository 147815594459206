import { Injectable, Output, EventEmitter, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Usuario } from "../models/usuario.model";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AutentificacionService {
  url = environment.url;
  usuario: Usuario;
  mensajeOk: string;

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private injector: Injector) {}

  login(email: string, password: string) {
    return this.http.post<any>(this.url + "back/login", { email , password }); 
  }
  emitirUsuarioLogueado() {
    this.getLoggedInName.emit(this.usuario);
  }
  getUsuario() {
    return this.usuario;
  }

  getUsuarioPromise() {
    if (this.estaLogueado()) {
      const http = this.injector.get(HttpClient);
      const promise = new Promise<void>((resolve, reject) => {
        http
          .get<any>(this.url + "back/me")
          .toPromise()
          .then(
            (respuesta) => {
              if (respuesta.status == "ok") {
                this.usuario = respuesta.usuario;
                this.usuario.menues = respuesta.menues;
                resolve();
              } else {
                this.logOut();
                resolve();
              }
            },
            (error) => {
              this.logOut();
              resolve();
            }
          );
      });
      return promise;
    }
  }
  
  logOut() {
    const route = this.injector.get(Router);
    this.http.post<any>(this.url + "back/logout", {}).subscribe();
    this.usuario = null;
    localStorage.clear();
    sessionStorage.clear()
    route.navigate(["/login"]);
  }

  expulsar() {
    const route = this.injector.get(Router);
    this.usuario = null;
    localStorage.clear();
    sessionStorage.clear()
    route.navigate(["/login"]);
  }

  estaLogueado() {
    return localStorage.getItem("access_token") !== null;
  }
  
  verificaErrorAutentificacion(respuesta) {
    if (respuesta.codigo && respuesta.codigo == -1) {
      this.logOut();
    }
  }

  sendEmail() {
    return this.http.post<any>(this.url + "back/mail_test", {});
  }
}
